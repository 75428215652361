<template>
  <section class="aba-formulario-principal">
    <div class="pa-0 mt-4">
      <v-row>
        <v-col sm="12" md="12" cols="12" class="padding-bottom-half">
          <v-list two-line subheader>
            <v-subheader>Atividades</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Primárias</v-list-item-title>
                <v-list two-line subheader>
                  <v-list-item>
                    <v-list-item-title
                      v-text="empresa.atividades.atividade_principal[0].text"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      >Código:
                      {{
                        empresa.atividades.atividade_principal[0].code
                      }}</v-list-item-subtitle
                    >
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Secundárias</v-list-item-title>
                <v-list two-line subheader>
                  <v-list-item
                    v-for="(atividade, i) in empresa.atividades
                      .atividades_secundarias"
                    :key="i"
                  >
                    <v-list-item-title
                      v-text="atividade.text"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      >Código: {{ atividade.code }}</v-list-item-subtitle
                    >
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col sm="12" md="12" cols="12" class="padding-bottom-half">
          <v-list two-line subheader>
            <v-subheader>Situação</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-text="'Data da situação'"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="empresa.situacao.data_situacao"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-text="'Situação'"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="empresa.situacao.situacao"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-text="'EFR'"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="empresa.situacao.efr"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-text="'Motivo da situação'"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="empresa.situacao.motivo_situacao"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-text="'Situação especial'"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="empresa.situacao.situacao_especial"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-text="'Data da situação especial'"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="empresa.situacao.data_situacao_especial"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "outros",
  computed: {
    empresa: {
      get() {
        return this.$store.getters["empresas/getEmpresaUsuarios"];
      },
      set(value) {
        this.$store.dispatch("empresas/setEmpresaUsuarios", value);
      },
    },
  },
};
</script>

<style>
</style>