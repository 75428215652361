import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"aba-formulario-principal"},[_c('div',{staticClass:"pa-0 mt-4"},[_c(VRow,[_c(VCol,{staticClass:"padding-bottom-half",attrs:{"sm":"12","md":"12","cols":"12"}},[_c(VList,{attrs:{"two-line":"","subheader":""}},[_c(VSubheader,[_vm._v("Atividades")]),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Primárias")]),_c(VList,{attrs:{"two-line":"","subheader":""}},[_c(VListItem,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.empresa.atividades.atividade_principal[0].text)}}),_c(VListItemSubtitle,[_vm._v("Código: "+_vm._s(_vm.empresa.atividades.atividade_principal[0].code))])],1)],1)],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Secundárias")]),_c(VList,{attrs:{"two-line":"","subheader":""}},_vm._l((_vm.empresa.atividades
                    .atividades_secundarias),function(atividade,i){return _c(VListItem,{key:i},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(atividade.text)}}),_c(VListItemSubtitle,[_vm._v("Código: "+_vm._s(atividade.code))])],1)}),1)],1)],1)],1)],1),_c(VCol,{staticClass:"padding-bottom-half",attrs:{"sm":"12","md":"12","cols":"12"}},[_c(VList,{attrs:{"two-line":"","subheader":""}},[_c(VSubheader,[_vm._v("Situação")]),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s('Data da situação')}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.empresa.situacao.data_situacao)}})],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s('Situação')}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.empresa.situacao.situacao)}})],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s('EFR')}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.empresa.situacao.efr)}})],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s('Motivo da situação')}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.empresa.situacao.motivo_situacao)}})],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s('Situação especial')}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.empresa.situacao.situacao_especial)}})],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s('Data da situação especial')}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.empresa.situacao.data_situacao_especial)}})],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }